import React, {Component} from "react";

export default class UtterancesComments extends Component {
  constructor(props){ 
    super(props);
    this.commentBox = React.createRef(); 
  }
  componentDidMount () {
      let scriptEl = document.createElement("script");
      scriptEl.setAttribute("src", "https://utteranc.es/client.js");
      scriptEl.setAttribute("crossorigin","anonymous");
      scriptEl.setAttribute("async", true);
      scriptEl.setAttribute("repo", "bitcobblers/bitcobblers-com-comments");
      scriptEl.setAttribute("issue-term", "url");
      scriptEl.setAttribute( "theme", "github-light");
      this.commentBox.current.appendChild(scriptEl);
  }

  render() {
    return (
        <div>
          <div ref={this.commentBox} className="comment-box"/>          
        </div>
    );
  }
}
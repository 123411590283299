import React from "react"
import Helmet from "react-helmet"
import { withPrefix, graphql } from "gatsby"
import UtterancesComments from "../utterances-comments.component"
import HeaderPost from "../header-post.component"
import { MDXRenderer } from "gatsby-plugin-mdx";
import "../../../static/styles/prism.css"
import Footer from "../footer.component"
import Navigation from "../navigation.component"

const BlogPage = ({ pageContext, data }) => {

  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx  
  
  return (
    <main>
      <Helmet>
        <title>bitCobblers - {frontmatter.title}</title>        
        <script src={withPrefix('/prism.js')} type="text/javascript" />
        <html lang="en" />
      </Helmet>
      <HeaderPost></HeaderPost>
      <Navigation />
      <div className="max-w-screen-xl mx-auto">                
        <article className="pt-10 space-y-4">          
          <div className="grid grid-cols-2 md:grid-flow-col-dense">            
            <div className="px-5 col-span-2">
              <h1>{frontmatter.title}</h1>
              <h2>{frontmatter.subtitle}</h2>
              <h5 className="space-x-2 py-1">
                <span>{frontmatter.datePublished}</span>
                <span>by</span>
                {frontmatter.authors.map(writer => (
                <span key={writer} className="px-2 py-1 first:border-none border-l-2">                  
                    <span>{writer}</span>
                </span>))}
              </h5>
            </div>
            <a className="px-5 col-span-1" href={frontmatter.link} target="_blank" rel="noreferrer">
                <img src={frontmatter.image} alt={`Book cover for ${frontmatter.title}`} />
            </a>
            <div className="col-span-1 p-5">
                  <div>
                    <b>Publisher</b>:{frontmatter.publisher}</div>
                  <div><b>Date Published</b>:</div>
                  <div><b>ISBN-13</b>:{frontmatter.isbn}</div>
                  <div><b>Amazon</b>:<a href={frontmatter.link} target="_blank" rel="noreferrer">
                    {frontmatter.amazonId}</a></div>
            </div>
          </div>  
          
          <p className="px-5">{frontmatter.description}</p>
          
          <MDXRenderer>{body.toString()}</MDXRenderer>          
          <div className="col-3">            
            <ul className="list-group list-group-flush">
              {frontmatter.authors.map(writer => (
                <li key={writer.name} className="list-group-item" style={{ "textAlign": "center" }}>                  
                    <span>{writer.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </article>
        {frontmatter.published.toString().toLowerCase() === "true" ? (<UtterancesComments></UtterancesComments>) : "" }
        {/* <Footer></Footer> */}
      </div>
    </main>
  )
}
export default BlogPage
export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        slug
        isbn
        googleId
        amazonId
        blogger
        date        
        published
        title
        subtitle
        description
        authors
        datePublished
        publisher
        link        
        image
        tags        
      }
    }
  }
`